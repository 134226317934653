import axios from "axios";

export async function postGenerateBadge(participant_id, subject_id, body) {
  const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/ondemand/participant/${participant_id}/badges?subject_id=${subject_id}`;
  try {
    const req = await axios.post(URL, body);
    return req.data.response;
  } catch (error) {
    throw new Error(error.response?.data?.message || error?.message);
  }
}

export const getBadges = async (repo_id) => {
  const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/students/${repo_id}/badges`;
  try {
    const response = await axios.get(URL);
    const badges = response.data.response_data.response;
    return badges;
  } catch (error) {
    throw new Error(error);
  }
};

export const getStudentPublicBadge = async (repo_id, partner_badge_id) => {
  const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/students/${repo_id}/badges/${partner_badge_id}`;
  try {
    const response = await axios.get(URL);
    if (response.data.response_data.code !== 200) {
      const errorBadge = response.data.response_data.response;
      return errorBadge;
    } else {
      const badges = response.data.response_data.response;
      return badges;
    }
  } catch (error) {
    throw new Error(error);
  }
};
