import * as types from "../types";

const actions = {};

actions.getBadgesRequest = (partner_id) => {
  return {
    type: types.GET_BADGES_REQUEST,
    payload: partner_id,
  };
};

actions.getBadgesSuccess = (badges) => {
  return {
    type: types.GET_BADGES_SUCCESS,
    payload: badges,
  };
};

actions.getBadgesFail = (error) => {
  return {
    type: types.GET_BADGES_FAIL,
    payload: error,
  };
};

export { actions };
