import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import parse from "html-react-parser";
import { titleHTML } from "../../../helpers/title.helper";
import ErrorResponse from "../../../components/common/ErrorResponse";
import { Button } from "@mui/material";
import {
  generatePDF,
  handleDownloadImage,
} from "../../../helpers/handleImagesInStyle";
import { getStudentPublicBadge } from "../../../redux/api/badge";
import SktBadgePublicPage from "./SktBadgePublicPage";

const BadgePublicPage = () => {
  titleHTML("Insignia");
  
  const { repo_id, partner_badge_id } = useParams();

  //STATES
  const [achievementHTML, setAchievementHTML] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false)

  
  //EFFECTS
  useEffect(() => {
    getPublicBadge();
  }, []);

  //FUNCTIONS
  const getPublicBadge = async () => {
    setIsLoading(true);
    const response = await getStudentPublicBadge(repo_id, partner_badge_id);
    if(response.message) {
      setAchievementHTML(null);
      setIsLoading(false);
      setError(response.message);
      return;
    }
    setAchievementHTML(response);
    setIsLoading(false);
  };

  const sanitizerHtml = (html) => {
    const string = 'src="/';
    const regex = new RegExp(string, "g");
    return html.replace(regex, 'src="');
  };


  //RETURN
  return (
    <Wrapper>
      {isLoading ? (
        <SktBadgePublicPage />
      ) : !achievementHTML || error ? (
        <ErrorResponse message={error} />
      ) : (
        <Grid>
          <Aside>
            <ButtonCustom
              variant="contained"
              fullWidth
              color="primary"
              onClick={handleDownloadImage}
            >
              Descargar imagen
            </ButtonCustom>
            <ButtonCustom
              variant="contained"
              fullWidth
              color="primary"
              onClick={generatePDF}
            >
              Descargar PDF
            </ButtonCustom>
          </Aside>
          <Container id="toPdf">
            <div id="content">{parse(sanitizerHtml(achievementHTML?.html))}</div>
          </Container>
        </Grid>
      )}
    </Wrapper>
  );
};

export default BadgePublicPage;

const Wrapper = styled.div`
  padding: 2rem;
  min-height: 400px;
  height: 100vh;
`;

const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 5fr;
  gap: 1rem;
  @media (max-width: 1300px) {
    display: flex;
    flex-direction: column;
  }
`;

const Container = styled.div`
  height: 100vh;
  width: auto;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .certificadocontainer {
    flex: 1;
    padding: 1rem;
    transform: scale(0.75);
    transform-origin: center center;
    width: 100%;
    height: 200%;
    background-color: white !important;
  }
  @media (max-width: 1300px) {
    height: 80vh;
  }
`;

const Aside = styled.div`
  max-width: 200px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1.5rem;
  @media (max-width: 1300px) {
    flex-direction: row;
    justify-content: center;
    max-width: 100%;
  }
`;

const ButtonCustom = styled(Button)`
  height: 44px;
  width: 200px;
  border-radius: 20px;
`;
