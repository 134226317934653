import React, { useState } from "react";
import styled from "styled-components";
import { Button, IconButton, Modal, Tooltip } from "@mui/material";
import {
  CloseRounded,
  EventAvailableRounded,
  EventBusyRounded,
} from "@mui/icons-material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSelector } from "react-redux";

const BadgeCardModal = (props) => {
  const { isOpen, handleCloseModal, badge } = props;
  const { user } = useSelector((state) => state.auth);

  // STATE
  const [open, setOpen] = useState(false);

  // FUNCTIONS
  const handleTooltipOpen = () => {
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 1000);
  };

  const handleGoToBadge = () => {
    window.open(`/logros/${user?.repo_id}/${badge?.id}`, "_blank");
  };

  // RETURN
  return (
    <Modal open={isOpen} onClose={handleCloseModal}>
      <BadgeCardModalContainer>
        <Header>
          <TitleHeader>Insignia</TitleHeader>
          <IconButton onClick={handleCloseModal} size="large">
            <CloseRounded fontSize="small" />
          </IconButton>
        </Header>
        <Body>
          <FakeImg src={badge?.badge_image} />
          <BadgeWrapper>
            <Name>{badge?.title}</Name>
            <Name>{badge?.badge_name} </Name>
            <Description>{badge?.description}</Description>
            <DatesWrapper>
              {badge?.fechaExpedicion && (
                <DateWrapper>
                  <EventAvailableRounded fontSize="small" />
                  <DateText>
                    <b>Fecha expedición: </b>
                    {badge?.fechaExpedicion !== ''
                      ? badge?.fechaExpedicion
                      : "Sin expedición"}
                  </DateText>
                </DateWrapper>
              )}

              {badge?.fechaCaducidad && (
                <DateWrapper>
                  <EventBusyRounded fontSize="small" />
                  <DateText>
                    <b>Fecha caducidad: </b>
                    {badge?.fechaCaducidad !== ''
                      ? badge?.fechaCaducidad
                      : "Sin caducidad"}
                  </DateText>
                </DateWrapper>
              )}
            </DatesWrapper>
          </BadgeWrapper>
        </Body>
        <ButtonWrapper>
          <StyledButton
            size="small"
            variant="contained"
            color="primary"
            onClick={handleGoToBadge}
          >
            Ver insignia
          </StyledButton>
          <CopyToClipboard
            text={`https://app.acropolislab.com/logros/${user?.repo_id}/${badge?.id}`}
            onCopy={handleTooltipOpen}
          >
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              open={open}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title="Copiado"
              placement="top"
            >
              <StyledButton size="small" variant="contained" color="primary">
                Copiar link de acceso público
              </StyledButton>
            </Tooltip>
          </CopyToClipboard>
          <StyledButtonClose
            variant="outlined"
            color="primary"
            size="small"
            onClick={handleCloseModal}
          >
            Cerrar
          </StyledButtonClose>
        </ButtonWrapper>
      </BadgeCardModalContainer>
    </Modal>
  );
};

export default BadgeCardModal;

const BadgeCardModalContainer = styled.div`
  border: none;
  outline: none;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  width: max-content;
  max-width: 90vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  @media (max-width: 768px) {
    max-width: 100vw;
    width: 100%;
    height: 100%;
    border-radius: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: none;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  color: #b7b7b7;
  border-bottom: 1px solid #efefef;

  @media (max-width: 768px) {
    background-color: #b31d15;
    color: #ffffff;

    svg {
      color: #ffffff;
    }
  }
`;

const TitleHeader = styled.h1`
  font-size: 1.25rem;
  font-weight: 700;
`;

const Body = styled.div`
  display: flex;
  grid-gap: 2rem;
  padding: 2rem 2rem 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

const FakeImg = styled.img`
  width: 160px;
  height: 160px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
`;

const BadgeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  padding-right: 2rem;
`;

const Name = styled.h1`
  font-size: 1.25rem;
  font-weight: 700;
  color: #222222;
`;

const Description = styled.p`
  font-size: 1rem;
  color: #616161;
`;

const DatesWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 2rem;
  color: #616161;

  @media (max-width: 768px) {
    flex-direction: column;
    row-gap: 1rem;
  }
`;

const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
`;

const DateText = styled.span`
  font-size: 1rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  grid-gap: 1rem;
  padding: 1rem 2rem 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: auto;
  }
`;

const StyledButton = styled(Button)`
  justify-content: center;
  padding: 0.25rem 1rem;
  &.MuiButton-containedSizeSmall {
    border-radius: 100px;
  }
  :hover {
    background-color: var(--primary-one);
    color: #f4f4f4;
  }
  span,
  svg {
    font-size: 0.8rem;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
    padding: 0.15rem;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const StyledButtonClose = styled(Button)`
  justify-content: center;
  padding: 0.25rem 1rem;
  &.MuiButton-outlinedSizeSmall {
    border-radius: 100px;
  }
  :hover {
    background-color: var(--primary-one);
    color: #f4f4f4;
  }
  span,
  svg {
    font-size: 0.8rem;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
    padding: 0.15rem;
  }
  @media (max-width: 768px) {
    width: 100%;
    display: none;
  }
`;
