import React, { useEffect, useState } from "react";
import styled from "styled-components";

// Components
import MainCampus from "./components/MainCampus";
import ChallengeQuestions from "../../components/templates/home/ChallengeQuestions";
import CompleteProfile from "../../components/utils/complete-profile/CompleteProfile";
import ExpiredDate from "../../components/templates/home/ExpiredDate";
import MainPopup from "../../modules/popupModule/components/MainPopup";

// Redux
import { useSelector } from "react-redux";

// Helpers
import { titleHTML } from "../../helpers/title.helper";
import useLayout from "../../hooks/useLayout";

const Campus = () => {
    titleHTML("Campus");

    const { setMenu } = useLayout()

    const { ask_dracma_challenge, ask_profile_personalization } = useSelector((state) => state?.ondemand?.ondemand);

    const lastSeenPopup = window.localStorage.getItem("lastSeenPopup");
    const [onViewPopup, setOnViewPopup] = useState(false);

    useEffect(() => {
        setMenu("Home")

        const nowDate = new Date();
        const fecha = new Date(lastSeenPopup);
        const diff = nowDate - fecha;
        const diffHours = diff / (1000 * 60 * 60);

        if (diffHours >= 24) {
            window.localStorage.removeItem("lastSeenPopup");
            setOnViewPopup(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <ExpiredDate />
            {/* {ask_profile_personalization && <CompleteProfile />} */}
            {ask_dracma_challenge && (lastSeenPopup || !onViewPopup) && (
                <ChallengeQuestions />
            )}
            {(!lastSeenPopup || onViewPopup) && <MainPopup />}
            <HomeWrapper>
                <MainCampus />
            </HomeWrapper>
        </>
    );
};

export default Campus;

const HomeWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }
`;