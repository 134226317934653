import * as types from "../types";
import { call, put, takeEvery } from "redux-saga/effects";
import { badgesActions } from "../actions";
import { getBadges } from "../api/badge";

function* badgesRequest(action) {
  try {
    const res = yield call(getBadges, action.payload);
    yield put(badgesActions.getBadgesSuccess(res));
  } catch (error) {
    yield put(badgesActions.getBadgesFail(error));
  }
}

function* badgesWatcher() {
  yield takeEvery(types.GET_BADGES_REQUEST, badgesRequest);
}

export { badgesWatcher };
