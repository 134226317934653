import React, { useState } from "react";
import styled from "styled-components";
import BadgeCardModal from "./BadgeCardModal";
import ShareIcon from "@mui/icons-material/Share";

const BadgeCard = (props) => {
  const { value } = props;
  // STATE
  const [isOpen, setIsOpen] = useState(false);

  // FUNCTIONS
  function handleOpenModal() {
    setIsOpen(true);
  }

  function handleCloseModal() {
    setIsOpen(false);
  }

  return (
    <>
      <BadgeCardContainer onClick={handleOpenModal}>
        <Body>
          <FakeImg src={value?.badge_image} />
          <Type type={value?.badge_type}>{value?.badge_type}</Type>
          <Name>{value?.title}</Name>
          <Name>{value?.badge_name}</Name>
          <Description>{value?.badge_description}</Description>
        </Body>
        <Footer>
          <SharedButton>
            <ShareIcon />
          </SharedButton>
        </Footer>
      </BadgeCardContainer>
      {isOpen && (
        <BadgeCardModal
          isOpen={isOpen}
          handleCloseModal={handleCloseModal}
          badge={value}
        />
      )}
    </>
  );
};

export default BadgeCard;

const BadgeCardContainer = styled.div`
  height: 320px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  row-gap: 1rem;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  cursor: pointer;
`;

const FakeImg = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
`;

const Type = styled.span`
  font-size: 0.8rem;
  background-color: ${(props) =>
    props.type === "Certificado académico"
      ? "#5e80db"
      : props.type === "Insignia Académica"
      ? "#b31d15"
      : props.type === "KNOWLEDGE INFLUENCER"
      ? "#b75e26"
      : "#1e8065"};
  color: #ffffff;
  border-radius: 50px;
  padding: 0.25rem 0.5rem;
`;

const Name = styled.h2`
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #222222;
`;

const Description = styled.span`
  font-size: 0.8rem;
  color: #616161;
`;

const Body = styled.div`
  display: flex;
  width: calc(100% - 4rem);
  height: calc(320px - 60px - 4rem);
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 2rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;

const Footer = styled.div`
  height: 60px;
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #fafafa;
  align-items: center;
  overflow: hidden;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`;

const SharedButton = styled.div`
  cursor: pointer;
  border-radius: 100%;
  padding: 8px;
  margin-left: 1rem;
  :hover {
    background-color: #f3f3f3;
  }
  svg {
    color: #a8a8a8;
    width: 30px;
    height: 30px;
  }
`;
