import React, { useContext } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import * as encode from "nodejs-base64-encode";

// Material UI
import { WorkspacePremium } from "@mui/icons-material";

// Hooks
import useMyCertificate from "../../../../hooks/useMyCertificate";

// Redux
import { useSelector } from "react-redux";
import { sendEmailCertificate } from "../../../../redux/api/ondemand";

// Context
import CourseContext from "../../context/CourseContext"

const CertificateButtonProgress = (props) => {
    
    const {
        disabled = false,
        userOrigin,
    } = props;

    const {
        workshop,
        objId,
        certificado: certificate,
    } = useContext(CourseContext); 

    const { id } = useParams();
    const oaId = encode.decode(id, "base64");
    const { generateBadge } = useMyCertificate(
        workshop.nombre_publicacion,
        workshop.generar_insignia,
        workshop.id
    );

    const user = useSelector((state) => state.auth.user);
    const { repo_id, ondemand_id } = user;

    const ondemandSendCertificate = useSelector(
        (state) => state?.ondemand?.ondemand?.enable_auto_send_certificate
    );

    const handleOpenCertificate = () => {
        if (ondemandSendCertificate) {
            sendEmailCertificate(oaId, repo_id, ondemand_id);
        }

        generateBadge();

        if (!!certificate) {
            window.open(
                `https://constancias.aden.org/?idcod=OA-${objId}-${user.repo_id}-ADEN2022/`,
                "_blank"
            );
        } else {
            alert("No posee certificado");
        }
    };

    return (
        <Container>
            <AsideButton
                onClick={() => handleOpenCertificate()}
                active={!!certificate ? true : false}
                variant="contained"
                disabled={disabled}
                isAlone={userOrigin === "demo" ? false : true}
            >
                <CertificateIcon />
                Descarga tu certificado
            </AsideButton>
        </Container>
    );
};

export default CertificateButtonProgress;

const Container = styled.div`
    margin-top: 1rem;
    width: ${(p) => (p.isAlone ? "auto" : "100%")};
`;

const AsideButton = styled.button`
    cursor: pointer;
    display: ${({ active }) => active ? "flex" : "none"};
    justify-content: center;
    align-items: center;
    line-height: 20px;
    text-align: start;
    font-size: 14px;
    padding: 15px 15px 15px 8px;
    box-shadow: 0px 12px 24px -15px #0000001A, 0px 0px 10px -6px #00000040;
    border-radius: 20px;
    color: var(--secondary-one);
    background-color: var(--secondary-three);
    :disabled {
        background-color: var(--secondary-four);
        cursor: not-allowed;
    }
    :hover {
        box-shadow: 0px 12px 24px -15px #0000001A, 0px 0px 10px -6px #00000040;
    }
    /* width: ${(p) => (p.isAlone ? "auto" : "100%")}; */
    width: 100%;
    height: 60px;
    @media (max-width: 1024px) {
        margin-top: 15px;
        width: 100%;
    }
`;

const CertificateIcon = styled(WorkspacePremium)`
    margin-right: 5px;
`;
