import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BadgeCard from "./components/BadgeCard";
import BadgeSkeleton from "./components/BadgeSkeleton";
import DefaultSearch from "../../components/common/DefaultSearch";
import NoResultsFound from "../../components/common/NoResultsFound";
import { useDispatch, useSelector } from "react-redux";
import { badgesActions } from "../../redux/actions";
import { Text } from "../../components/common/Texts";

const Badges = () => {
  // REDUX
  const dispatch = useDispatch();
  const badges = useSelector((state) => state.badges.badges);
  const user = useSelector((state) => state.auth.user);
  const listLoading = useSelector((state) => state.badges.listLoading);

  //STATES
  const [searchedValues, setSearchedValues] = useState(null);
  const [search, setSearch] = useState("");

  // EFFECTS
  useEffect(() => {
    if (badges === null) {
      dispatch(badgesActions.getBadgesRequest(user.repo_id));
    }
  }, []);

  useEffect(() => {
    if (badges !== null) {
      setSearchedValues(badges);
    }
  }, [badges]);

  useEffect(() => {
    if (search !== null) {
      if (search === "") {
        setSearchedValues(badges);
      } else {
        setSearchedValues(
          badges.filter((badge) => {
            const searchTerm = search.toLowerCase();
            const nombreMatch = badge?.badge_name
              ? badge?.badge_name?.toLowerCase().includes(searchTerm)
              : false;
            const titleMatch = badge?.title
              ? badge?.title?.toLowerCase().includes(searchTerm)
              : false;
            const tipoMatch = badge?.badge_type
              ? badge?.badge_type?.toLowerCase().includes(searchTerm)
              : false;
            const descripcionMatch = badge?.badge_description
              ? badge?.badge_description?.toLowerCase().includes(searchTerm)
              : false;
            return nombreMatch || titleMatch || tipoMatch || descripcionMatch;
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  // FUNCTIONS
  function handleChangeSearch(e) {
    setSearch(e.target.value);
  }

  // RETURN
  if (!badges || searchedValues === null || listLoading) {
    return <BadgeSkeleton />;
  }

  return (
    <Container>
      <WrapperSearch>
        <DefaultSearch
          handleChange={handleChangeSearch}
          placeholder="Buscar ..."
          search={search}
        />
      </WrapperSearch>
      <BadgesWrapper>
        {searchedValues.length > 0 ? (
          searchedValues.map((badge) => (
            <BadgeCard value={badge} key={badge.id} />
          ))
        ) : search !== "" ? (
          <NoResultsFound message="¡No se encuentra insignia para esta búsqueda!" />
        ) : (
          <Text
            fontSize="24px"
            fontWeight="600"
            textAlign={"center"}
            style={{ width: "100%" }}
            color={"#bfbfbf"}
          >
            Aún no tienes insignias. Sigue cursando para obtenerlas.
          </Text>
        )}
      </BadgesWrapper>
    </Container>
  );
};

export default Badges;

const Container = styled.div`
  padding: 2rem;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 8px 22px -6px rgba(24, 39, 75, 0.12),
    0px 14px 64px -4px rgba(24, 39, 75, 0.12);
  border-radius: 20px;
`;

const BadgesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  padding: 2rem;
  align-items: start;
  justify-content: center;
  justify-items: center;
  gap: 1rem;
  min-height: 70vh;
`;

const WrapperSearch = styled.div`
  display: flex;
  justify-content: space-between;
`;
