import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

// COMPONENTS
import Campus from "./pages/home/Campus";
import Workshops from "./pages/workshops/Workshops";
import Content from "./pages/microlearning/Content";
import Profile from "./pages/profile/Profile";
import PublicProfile from "./pages/profile/PublicProfile";
import Microlearnings from "./pages/microlearning/Microlearnings";
import Logout from "./pages/auth/Logout";
import Business from "./pages/ondemand/Business";
import Events from "./pages/events/Events";
import Dracma from "./pages/dragmas/Dracma";
import Tickets from "./pages/tickets/Tickets";
import Iframe from "./pages/networking/Iframe";

// ROUTES
import PublicRoute from "./routes/PublicRoute";
import NotFound from "./pages/error/NotFound";
import RedirectTo from "./pages/RedirectTo";
import IA from "./pages/recommendation/IA";
import Certificate from "./pages/certificate/Certificate";
import CourseSubject from "./pages/course/CourseSubject";
import Challege from "./pages/ondemand/Challenge";
import Course from "./pages/course/Course";
import LandingProduct from "./pages/ecommerce/Product";
// import Video from './pages/video/Video';
import Asistencia from "./pages/asistencia/Asistencia";
import MyFavs from "./pages/favorites/MyFavs";

import ErrorRoute from "./routes/ErrorRoute";
import Progress from "./pages/progress/Progress";
import StudyPlan from "./pages/progress/StudyPlan";
import Notification from "./pages/notifications/Notification";
import ForumSynchronous from "./pages/events/ForumSynchronous";
import Referred from "./pages/referred/Referred";
import EducationalOffer from "./modules/educationalOffer/index";
import ViewResource from "./pages/viewResource/ViewResource";
import LoadingPage from "./pages/loading/LoadingPage";
import Support from "./pages/support/Support";
import MyReferrals from "./pages/referred/MyReferrals";
import AutoLoginRoute from "./routes/AutoLoginRoute";
import Blog from "./pages/ondemand/Blog";
import Article from "./pages/ondemand/Article";
import AlabAuth from "./pages/auth/AlabAuth";
import PrivateRoute from "./routes/PrivateRoute";
import Cookies from './pages/cookies/Cookies'
import MyCertificates from "./pages/certificates/MyCertificates";
import IndividualCertificate from "./pages/certificates/IndividualCertificate";
import DocuSign from "./pages/docuSign/DocuSign";
import Challenges from "./pages/ondemand/Challenges";
import ScheduleInCompany from "./pages/scheduleInCompany/ScheduleInCompany";
import BadgePublicPage from "./pages/badges/components/BadgePublicPage";
import Badges from "./pages/badges/Badges";


const Routes = () => {
  return (
    <Switch>
      {/* RUTAS PUBLICAS ----------------------------------------------------------------------- */}

      {/* AutoLogin */}
      <AutoLoginRoute path="/autologin" exact />

      {/* Auth */}
      <PublicRoute 
        layout="common" 
        path="/auth" 
        exact 
        component={AlabAuth} 
      />

      {/* E-Commerce */}
      <PublicRoute
        layout="e-commerce"
        blockRedirect={true}
        path="/producto/:id"
        exact
        component={LandingProduct}
      />
      {/* Prueba DocuSign */}
      <PublicRoute
        layout="certificate"
        blockRedirect={true}
        path="/docusign-example"
        exact
        component={DocuSign}
      />
      {/* Referidos */}
      <PublicRoute
        layout="certificate"
        blockRedirect={true}
        path="/referidos/:id"
        exact
        component={Referred}
      />
      <PublicRoute
        layout="certificate"
        blockRedirect={true}
        path="/referidos"
        exact
        component={Referred}
      />

      {/* Certificado */}
      <PublicRoute
        layout="certificate"
        blockRedirect={true}
        path="/certificate/:obj/:user"
        component={Certificate}
      />

      {/* Insignias */}
      <PublicRoute
        layout="certificate"
        blockRedirect={true}
        path="/logros/:repo_id/:partner_badge_id"
        component={BadgePublicPage}
      />

      {/* Redirect default */}
      <Route path="/redirect" exact component={RedirectTo} />

      {/** RUTAS PRIVADAS -------------------------------------------------------------------------*/}

      {/* Auth */}
      <PrivateRoute
        layout="common"
        path="/logout"
        exact
        component={Logout}
        blockedRoles={["alumno", "docente", "academico", "visita", "ondemand"]}
      />

      {/* Borrar datos */}

      <PrivateRoute
        layout="common"
        path="/reload"
        exact
        component={Cookies}
      />

      {/* Alumno */}
      <PrivateRoute
        layout="common"
        path="/asignatura/:id"
        exact
        component={CourseSubject}
        blockedRoles={["docente", "academico"]}
        permissionRoles={["alumno", "ondemand"]}
      />
      <PrivateRoute
        layout="common"
        path="/mis-certificados"
        title="Mis certificados"
        exact
        component={MyCertificates}
        blockedRoles={["docente", "academico"]}
        permissionRoles={["alumno", "ondemand"]}
      />
      <PrivateRoute
        layout="common"
        path="/mis-certificados/:partner/:id"
        exact
        component={IndividualCertificate}
        blockedRoles={["docente", "academico"]}
        permissionRoles={["alumno", "ondemand"]}
      />
      <PrivateRoute
        layout="common"
        path="/oferta-educativa"
        title="Oferta Educativa"
        exact
        component={EducationalOffer}
        blockedRoles={[]}
        permissionRoles={[
          "docente",
          "alumno",
          "visita",
          "ondemand",
          "academico",
        ]}
      />
      <PrivateRoute
        layout="common"
        path="/mi-progreso"
        title="Mi progreso"
        exact
        component={Progress}
        blockedRoles={["docente", "academico", "ondemand"]}
        permissionRoles={["alumno", "visita"]}
      />
      <PrivateRoute
        layout="common"
        path="/mi-progreso/:id"
        exact
        component={StudyPlan}
        blockedRoles={["docente", "academico", "ondemand"]}
        permissionRoles={["alumno", "visita"]}
      />

      {/* Ondemand */}
      <PrivateRoute
        layout="common"
        path="/mi-empresa/:id"
        exact
        component={Business}
        blockedRoles={["docente", "academico"]}
        permissionRoles={["ondemand"]}
      />

      <PrivateRoute
        layout="common"
        path="/mis-logros"
        exact
        component={Badges}
        blockedRoles={["docente", "academico"]}
        permissionRoles={["ondemand", "alumno"]}
      />

      {/* Ondemand _ In Company */}
      <PrivateRoute
        layout="common"
        path="/calendario"
        exact
        component={ScheduleInCompany}
        blockedRoles={["docente", "academico"]}
        permissionRoles={["ondemand"]}
      />

      {/* Usuario en comun */}
      <PrivateRoute
        layout="common"
        path="/campus"
        title="Home"
        exact
        component={Campus}
        blockedRoles={["docente", "academico"]}
        permissionRoles={["ondemand", "alumno", "visita"]}
      />
      <PrivateRoute
        layout="common"
        path="/view/:type/:id"
        exact
        component={ViewResource}
        blockedRoles={[]}
        permissionRoles={[
          "ondemand",
          "alumno",
          "visita",
          "docente",
          "academico",
        ]}
      />
      <PrivateRoute
        layout="iframe"
        path="/chatear"
        exact
        component={Iframe}
        blockedRoles={["academico"]}
        permissionRoles={["ondemand", "alumno", "docente", "visita"]}
      />
      <PrivateRoute
        layout="iframe"
        path="/desafio"
        title="Desafíos"
        exact
        component={Challege}
        blockedRoles={["docente", "academico"]}
        permissionRoles={["ondemand", "alumno", "visita"]}
      />
      <PrivateRoute
        layout="iframe"
        path="/desafio/:id"
        exact
        component={Challege}
        blockedRoles={["docente", "academico"]}
        permissionRoles={["ondemand", "alumno", "visita"]}
      />
      <PrivateRoute
        layout="common"
        path="/desafios"
        title="Desafíos"
        exact
        component={Challenges}
        blockedRoles={["docente", "academico"]}
        permissionRoles={["ondemand", "alumno", "visita"]}
      />
      <PrivateRoute
        layout="common"
        path="/perfil"
        title="Perfil"
        exact
        component={Profile}
        blockedRoles={["docente"]}
        permissionRoles={["ondemand", "alumno", "visita", "academico"]}
      />
      <PrivateRoute
        layout="common"
        path="/perfil-publico/:id"
        exact
        component={PublicProfile}
        blockedRoles={[]}
        permissionRoles={[
          "ondemand",
          "alumno",
          "visita",
          "docente",
          "academico",
        ]}
      />
      <PrivateRoute
        layout="common"
        path="/peticiones-reclamos"
        title="Peticiones y Reclamos"
        exact
        component={Tickets}
        blockedRoles={[]}
        permissionRoles={[
          "ondemand",
          "alumno",
          "visita",
          "docente",
          "academico",
        ]}
      />
      <PrivateRoute
        layout="common"
        path="/loading/:rol"
        exact
        component={LoadingPage}
        blockedRoles={[]}
        permissionRoles={[
          "ondemand",
          "alumno",
          "visita",
          "docente",
          "academico",
        ]}
      />
      <PrivateRoute
        layout="common"
        path="/recomendaciones"
        exact
        component={IA}
        blockedRoles={[]}
        permissionRoles={[
          "ondemand",
          "alumno",
          "visita",
          "docente",
          "academico",
        ]}
      />
      <PrivateRoute
        layout="common"
        path="/competencia"
        exact
        component={Dracma}
        blockedRoles={[]}
        permissionRoles={[
          "ondemand",
          "alumno",
          "visita",
          "docente",
          "academico",
        ]}
      />
      {/* OCULTADO EL CHAT PROVISORIAMENTE POR QUE EL SEBA LO PIDIÓ */}
      {/* <PrivateRoute
        layout="common"
        path="/networking"
        exact
        component={Directory}
        blockedRoles={[]}
        permissionRoles={[
          "ondemand",
          "alumno",
          "visita",
          "docente",
          "academico",
        ]}
      /> */}
      <PrivateRoute
        layout="common"
        path="/notificaciones"
        title="Notificaciones"
        exact
        component={Notification}
        blockedRoles={[]}
        permissionRoles={[
          "ondemand",
          "alumno",
          "visita",
          "docente",
          "academico",
        ]}
      />
      <PrivateRoute
        layout="common"
        path="/workshops"
        title="Cursos abiertos"
        exact
        component={Workshops}
        blockedRoles={[]}
        permissionRoles={[
          "ondemand",
          "alumno",
          "visita",
          "docente",
          "academico",
        ]}
      />
      <PrivateRoute
        layout="course"
        path="/curso/:id"
        exact
        component={Course}
        blockedRoles={[]}
        permissionRoles={[
          "ondemand",
          "alumno",
          "visita",
          "docente",
          "academico",
        ]}
      />
      <PrivateRoute
        layout="common"
        path="/support"
        title="Tutoriales de ayuda"
        exact
        component={Support}
        blockedRoles={[]}
        permissionRoles={[
          "ondemand",
          "alumno",
          "visita",
          "docente",
          "academico",
        ]}
      />
      <PrivateRoute
        layout="common"
        path="/biblioteca"
        title="Biblioteca"
        exact
        component={Content}
        blockedRoles={[]}
        permissionRoles={[
          "ondemand",
          "alumno",
          "visita",
          "docente",
          "academico",
        ]}
      />
      <PrivateRoute
        layout="common"
        path="/asistencia"
        exact
        component={Asistencia}
        blockedRoles={["academico", "docente", "alumno", "visita", "ondemand"]}
      />
      <PrivateRoute
        layout="common"
        path="/biblioteca/:id"
        exact
        component={Microlearnings}
        blockedRoles={[]}
        permissionRoles={[
          "ondemand",
          "alumno",
          "visita",
          "docente",
          "academico",
        ]}
      />
      <PrivateRoute
        layout="common"
        path="/favoritos"
        title="Favoritos"
        exact
        component={MyFavs}
        blockedRoles={[]}
        permissionRoles={[
          "ondemand",
          "alumno",
          "visita",
          "docente",
          "academico",
        ]}
      />
      
      {/* OCULTADO EL CHAT PROVISORIAMENTE POR QUE EL SEBA LO PIDIÓ */}
      {/* <PrivateRoute
        layout="chat"
        path="/networking/chat"
        exact
        component={Chat}
        blockedRoles={[]}
        permissionRoles={[
          "ondemand",
          "alumno",
          "visita",
          "docente",
          "academico",
        ]}
      /> */}
      <PrivateRoute
        layout="common"
        path="/eventos"
        title="Eventos"
        exact
        component={Events}
        blockedRoles={[]}
        permissionRoles={[
          "ondemand",
          "alumno",
          "visita",
          "docente",
          "academico",
        ]}
      />
      <PrivateRoute
        layout="common"
        path="/evento"
        title="Eventos"
        exact
        component={Events}
        blockedRoles={[]}
        permissionRoles={[
          "ondemand",
          "alumno",
          "visita",
          "docente",
          "academico",
        ]}
      />
      <PrivateRoute
        layout="common"
        path="/foros-sincronicos"
        exact
        component={ForumSynchronous}
        blockedRoles={[]}
        permissionRoles={[
          "ondemand",
          "alumno",
          "visita",
          "docente",
          "academico",
        ]}
      />

      <PrivateRoute
        layout="common"
        path="/blog"
        exact
        component={Blog}
        blockedRoles={[]}
        permissionRoles={[
          "ondemand",
          "alumno",
          "visita",
          "docente",
          "academico",
        ]}
      />
      <PrivateRoute
        layout="common"
        path="/blog/:id/:slug"
        exact
        component={Article}
        blockedRoles={[]}
        permissionRoles={[
          "ondemand",
          "alumno",
          "visita",
          "docente",
          "academico",
        ]}
      />
      <PrivateRoute
        layout="common"
        path="/mis-referidos"
        exact
        component={MyReferrals}
        blockedRoles={["academico"]}
        permissionRoles={["ondemand", "alumno", "visita", "docente"]}
      />
      {/* Errores */}
      <ErrorRoute
        layout="common"
        path="/not-found"
        exact
        component={NotFound}
        blockedRoles={[]}
        permissionRoles={[
          "ondemand",
          "alumno",
          "visita",
          "docente",
          "academico",
        ]}
      />
      {/* Redirect default */}
      <Redirect path="*" to="/campus" />
    </Switch>
  );
};

export default React.memo(Routes);
