import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export const convertImageToBase64 = async (url) => {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error("Error convirtiendo la imagen a Base64:", error);
    return null;
  }
};

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const replaceImagesInStylesWithBase64 = async () => {
  const styleSheets = document.styleSheets;
  for (const sheet of styleSheets) {
    try {
      const rules = sheet.cssRules || sheet.rules;
      for (const rule of rules) {
        if (rule.style && rule.style.backgroundImage) {
          const backgroundImage = rule.style.backgroundImage;
          const urlMatch = backgroundImage.match(/url\("(.*?)"\)/);
          if (urlMatch && urlMatch[1] !== "none") {
            console.log(`URL detectada en estilos:`, urlMatch[1]);
            const base64Image = await convertImageToBase64(urlMatch[1]);
            if (base64Image) {
              rule.style.backgroundImage = `url(${base64Image})`;
              console.log(`Imagen en estilos convertida a Base64:`, rule);
            }
          }
        }
      }
    } catch (error) {
      console.warn("No se pudo acceder a una hoja de estilos:", error);
    }
  }
};

export const replaceImagesWithBase64 = async (element) => {
  await delay(500);
  const allElements = element.getElementsByTagName("*");
  for (const el of allElements) {
    const computedStyle = window.getComputedStyle(el);
    const backgroundImage = computedStyle.getPropertyValue("background-image");
    console.log(`Elemento:`, el, `Background-Image:`, backgroundImage);
    const urlMatch = backgroundImage.match(/url\("(.*?)"\)/);
    if (urlMatch && urlMatch[1] !== "none") {
      console.log(`URL detectada:`, urlMatch[1]);
      const base64Image = await convertImageToBase64(urlMatch[1]);
      if (base64Image) {
        el.style.backgroundImage = `url(${base64Image})`;
        console.log(`Imagen convertida a Base64 para el elemento:`, el);
      }
    }
  }
};

export const generatePDF = async () => {
  try {
    await replaceImagesInStylesWithBase64();

    const element = document.getElementById("toPdf");
    if (!element) throw new Error("Elemento no encontrado");

    await replaceImagesWithBase64(element);

    const options = {
      useCORS: true,
      allowTaint: true,
      scale: 3,
      backgroundColor: "white",
    };

    const canvas = await html2canvas(element, options);
    const imgData = canvas.toDataURL("image/png");

    const pdf = new jsPDF({
      orientation: "landscape",
      unit: "mm",
      format: "a4",
    });
    const imgWidth = 297;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;

    pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight, undefined, "FAST");
    pdf.save("Insignia.pdf");
  } catch (error) {
    console.error("Error generando el PDF:", error);
  }
};

export const handleDownloadImage = async () => {
  try {
    await replaceImagesInStylesWithBase64();

    const element = document.getElementById("toPdf");
    if (!element) throw new Error("Elemento no encontrado");

    await replaceImagesWithBase64(element);

    const canvas = await html2canvas(element, {
      useCORS: true,
      allowTaint: true,
      scale: 3,
      backgroundColor: "white",
    });

    const link = document.createElement("a");
    link.href = canvas.toDataURL("image/png");
    link.download = "Insignia.png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error descargando la imagen:", error);
  }
};
