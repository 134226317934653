import styled from "styled-components";
import { Skeleton } from "@mui/material";

const SktBadgePublicPage = () => {
  return (
    <Grid>
      <Aside>
        <Skeleton animation="wave" height={100} width={200} />
        <Skeleton animation="wave" height={100} width={200} />
      </Aside>
      <Skeleton animation="wave" height="100vh" width="100%" />
    </Grid>
  );
};

export default SktBadgePublicPage;

const Grid = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 5fr;
  gap: 1rem;
  @media (max-width: 1300px) {
    display: flex;
    flex-direction: column;
  }
`;

const Aside = styled.div`
  max-width: 200px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1.5rem;
  @media (max-width: 1300px) {
    flex-direction: row;
    justify-content: center;
    max-width: 100%;
  }
`;
