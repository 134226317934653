import React, { useState } from "react";
import moment from "moment";
import styled from "styled-components";
import { darken } from "polished";
import TodayRoundedIcon from "@mui/icons-material/TodayRounded";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { ScheduleRounded } from "@mui/icons-material";
import ModalICEvent from "./ModalICEvent";
import { Text } from "../../../components/common/Texts";
import LinkToEvent from "./LinkToEvent";

const DateCardHome = (props) => {
  const { event, primary_color } = props;
  const [openModal, setOpenModal] = useState(false);

  moment.updateLocale("es", {
    months:
      "Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre".split(
        "_"
      ),
    monthsShort:
      "Enero._Feb._Mar_Abr._May_Jun_Jul._Ago_Sept._Oct._Nov._Dec.".split("_"),
    weekdays: "Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sabado".split("_"),
    weekdaysShort: "Dom._Lun._Mar._Mier._Jue._Vier._Sab.".split("_"),
    weekdaysMin: "Do_Lu_Ma_Mi_Ju_Vi_Sa".split("_"),
  });

  const eventDateBegin =
    typeof event?.date_begin_located === "string"
      ? new Date(event?.date_begin_located.replace(/\b(dic)\b/g, "dec"))
      : null;
  const dayOfMonthEventDateBegin =
    eventDateBegin instanceof Date && !isNaN(eventDateBegin)
      ? eventDateBegin.getDate()
      : null;

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  function getMonthNameInSpanish(monthNameInEnglish) {
    const monthNamesTranslation = {
      0: "Enero",
      1: "Febrero",
      2: "Marzo",
      3: "Abril",
      4: "Mayo",
      5: "Junio",
      6: "Julio",
      7: "Agosto",
      8: "Septiembre",
      9: "Octubre",
      10: "Noviembre",
      11: "Diciembre",
    };

    return monthNamesTranslation[monthNameInEnglish] || "Mes inválido";
  }

  const extractEventTimes = (event) => {
    const options = { hour: "2-digit", minute: "2-digit", hour12: false };
    const startTime = event?.date_begin_located
      ? new Date(
          event.date_begin_located
            .replace(/\b(dic)\b/g, "dec")
            .replace(/\b(abr)\b/g, "apr")
        ).toLocaleTimeString([], { ...options, month: "short", day: "numeric" })
      : null;
    const endTime = event?.date_end_located
      ? new Date(
          event.date_end_located
            .replace(/\b(dic)\b/g, "dec")
            .replace(/\b(abr)\b/g, "apr")
        ).toLocaleTimeString([], { ...options })
      : null;
    const concat = `${startTime} - ${endTime}`;
    return concat;
  };

  const concatLocation = (event) => {
    const parts = [];
    if (event?.address) parts.push(event.address);
    if (event?.city) parts.push(event.city);
    if (event?.country) parts.push(event.country);

    const location = parts.join(", ");
    const locationFull = location.length > 0 ? `${location}` : false;
    return locationFull;
  };

  return (
    <>
      {openModal && (
        <ModalICEvent
          openModal={openModal}
          event={event}
          hours={extractEventTimes(event)}
          location={concatLocation(event)}
          handleCloseModal={handleCloseModal}
          primary_color={primary_color}
        />
      )}
      <CardStyle colorPrimary={primary_color} onClick={handleOpenModal}>
        <Body>
          <Header>
            <DateWrapper
              backgroundColor={primary_color ? primary_color : "#b31d15"}
            >
              <TodayRoundedIcon />
              <p>{dayOfMonthEventDateBegin}</p>
              <p> de </p>
              <p> {getMonthNameInSpanish(eventDateBegin.getMonth())}</p>
            </DateWrapper>
            {event?.type === "Virtual" && event?.registration_url !== "" && (
              <LinkToEvent
                primary_color={primary_color}
                link={event?.registration_url}
              />
            )}
          </Header>
          <DataWrapper>
            <Title>
              <Text fontSize="18px" fontWeight="700" color="#fffff">
                {event?.name}
              </Text>
            </Title>
            <Text
              fontSize="14px"
              fontWeight="400"
              color="#fffff"
              textTransform="uppercase"
            >
              {event?.type}
            </Text>
            <WrapperText>
              <ScheduleRounded />
              <Text
                fontSize="13px"
                fontWeight="700"
                color="#fffff"
                textTransform="capitalize"
              >
                Hora:{" "}
              </Text>
              <Text fontSize="13px" fontWeight="400" color="#616161">
                {/* {getFullTimeFormat(event.date_begin_located)} -{" "}
                {getFullTimeFormat(event.date_end_located)} */}
                {extractEventTimes(event)}
              </Text>
            </WrapperText>
            {event.type !== "Virtual" && concatLocation(event).length > 0 && (
              <WrapperText>
                <LocationOnIcon />
                <Text
                  fontSize="13px"
                  fontWeight="700"
                  color="#fffff"
                  textTransform="capitalize"
                >
                  Ubicación:{" "}
                </Text>
                <Text fontSize="13px" fontWeight="400" color="#616161">
                  {concatLocation(event)}
                </Text>
              </WrapperText>
            )}
          </DataWrapper>
        </Body>
      </CardStyle>
    </>
  );
};

export default DateCardHome;

const CardStyle = styled.div`
  cursor: pointer;
  height: 150px;
  width: calc(100% - 10px);
  padding: 1rem 0;
  gap: 0.5rem;
  box-shadow: 0px 4px 4px -2px #18274b14;
  box-shadow: 0px 2px 4px -2px #18274b1f;
  margin-bottom: 1rem;
  display: inline-flex;
  align-items: stretch;
  border-radius: 10px;
  border-top: 1px solid #dcdcdc;
  border-right: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
  border-left: 8px solid #dcdcdc;
  transition: all 0.3s ease-in-out;

  :hover {
    box-shadow: 0px 8px 8px -4px #18274b14, 0px 4px 4px 0px #00000040;
  }
`;

const Body = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  gap: 0.3rem;
  position: relative;
  width: calc(100% - 25px);
  max-width: calc(100% - 25px);
  background: white;
  transition: ease-in-out 0.3s all;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0.5rem;
  align-items: center;
`;

const DateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0.3rem;
  padding: 0.5rem 1rem;
  background: ${(p) =>
    `linear-gradient(180deg, ${p.backgroundColor}, ${darken(
      0.025,
      p.backgroundColor
    )})`};
  border-radius: 100px;
  svg,
  p {
    color: #fff;
    font-weight: 700;
    font-size: 16px;
  }
`;

const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
`;

const WrapperText = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: 95%;
  max-height: 70px;
  overflow: hidden;
  text-overflow: ellipsis;

  svg {
    font-size: 16px;
    color: #a8a8a8;
  }
`;

const Title = styled.div`
  max-height: 45px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
